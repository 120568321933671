

































































































import { RouterNames } from "@/router/routernames";
import { appointment } from "@/shared/dtos/appointment";
import appointmentModule from "@/store/modules/appointment-module";
import AppointmentModule from "@/store/modules/appointment-module";
import { UtilsDate } from "@/utils/utils-date";
import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {}
})
export default class AsistenteNewCita extends Vue {
  public e1 = 1;
  public cita: appointment = new appointment({
    fecha: UtilsDate.formatDateToVueTify(new Date(), false)
  });

  public created() {
    appointmentModule.getNutricionistas();
  }
  public get stilo() {
    //this.cita.id_nutricionista
    let h = this.$vuetify.breakpoint.height - 220;
    return (
      "height:" +
      h +
      "px;overflow: auto;     align-items: center;justify-content: center;display: table;"
    );
  }
  public get nutricionistas() {
    return appointmentModule.nutricionistas;
  }

  public Close() {
    this.$router.push({ name: RouterNames.Home });
  }
  public SelectNutri(nutri: any) {
    this.cita.Id_nutricionista = nutri.id;
    this.cita.nombre_nutricionista = nutri.nombre;
    this.cita.direccion = nutri.direccion;
    this.cita.ResourceID = 1;
    this.e1 = this.e1 + 1;
  }

  public Save() {
    if (this.cita.Id_nutricionista === undefined) {
      this.cita.Id_nutricionista = this.nutricionistas[0].id;
    }
    if (this.cita.Id_nutricionista === 0) {
      this.cita.Id_nutricionista = this.nutricionistas[0].id;
    }
    this.cita.StartDate = new Date(
      Number.parseInt(this.cita.fecha.toString().split("-")[0]),
      Number.parseInt(this.cita.fecha.toString().split("-")[1]) - 1,
      Number.parseInt(this.cita.fecha.toString().split("-")[2]),
      Number.parseInt(this.cita.hora.split(":")[0]),
      Number.parseInt(this.cita.hora.split(":")[1])
    );
    this.cita.EndDate = UtilsDate.AddMinutos(this.cita.StartDate, 15);

    appointmentModule
      .guardarappointment(this.cita)
      .then(() => this.$router.push({ name: RouterNames.citas_menu }));
  }
}
