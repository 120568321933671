export class UtilsDate {
    public static AddMinutos(fecha: Date, minutos: number): Date {
        //1000 milisegundos en un segundo * 60 segundos en un minuto * minutos.
        return new Date(fecha.getTime() + ((1000 * 60) * minutos));
    }//https://stackoverflow.com/questions/42261302/how-to-add-a-day-to-a-date-in-typescript
    public static AddHoras(fecha: Date, horas: number): Date {
        //1000 milisegundos en un segundo * 60 segundos en un minuto * horas.
        return new Date(fecha.getTime() + ((1000 * 60 * 60) * horas));
    }
    public static AddDias(fecha: Date, dias: number): Date {
        //1000 milisegundos en un segundo * 60 segundos en un minuto * 60 minutos en una hora * 24 horas* dias
        return new Date(fecha.getTime() + ((1000 * 60 * 60 * 24) * dias));
    }

    public static formatDateToVueTify(fecha: Date, withTime: boolean) {
        return withTime
            ? `${fecha.getFullYear()}-${fecha.getMonth() +
            1}-${fecha.getDate()} ${fecha.getHours()}:${fecha.getMinutes()}`
            : `${fecha.getFullYear()}-${fecha.getMonth() + 1}-${fecha.getDate()}`;
    }

    public static formatDateToVueTifyDDMMAAAA(fecha: Date, withTime: boolean) {
        return withTime
            ? `${fecha.getDate()}/${fecha.getMonth() +
            1}/${fecha.getFullYear()} ${fecha.getHours()}:${fecha.getMinutes()}`
            : `${fecha.getDate()}/${fecha.getMonth() + 1}/${fecha.getFullYear()}`;
    }

    //Libreria para fechas
    //https://github.com/datejs/Datejs


    public static convert(d: any) {
        // Converts the date in d to a date-object. The input can be:
        //   a date object: returned without modification
        //  an array      : Interpreted as [year,month,day]. NOTE: month is 0-11.
        //   a number     : Interpreted as number of milliseconds
        //                  since 1 Jan 1970 (a timestamp) 
        //   a string     : Any format supported by the javascript engine, like
        //                  "YYYY/MM/DD", "MM/DD/YYYY", "Jan 31 2009" etc.
        //  an object     : Interpreted as an object with year, month and date
        //                  attributes.  **NOTE** month is 0-11.
        return (
            d.constructor === Date ? d :
                d.constructor === Array ? new Date(d[0], d[1], d[2]) :
                    //@ts-ignore
                    d.constructor === Number ? new Date(d) :
                        //@ts-ignore
                        d.constructor === String ? new Date(d) :
                            typeof d === "object" ? new Date(d.year, d.month, d.date) :
                                NaN
        );
    }




}